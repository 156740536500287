import React from 'react'

const SvgEmail1 = props => (
  <svg width={28} height={20} {...props}>
    <path
      fillRule='evenodd'
      fill='#FD5C1F'
      d='M14.146 12.702a3.389 3.389 0 01-2.241-.795l-1.983-1.68a1.095 1.095 0 01-.123-1.558 1.131 1.131 0 011.58-.122l1.983 1.68c.411.329 1 .329 1.411 0l10.419-7.902-21.286-.077c-.618 0-1.12-.495-1.12-1.105S3.288.038 3.906.038h21.286c1.236-.07 2.295.861 2.366 2.08a2.199 2.199 0 01-.977 1.953l-10.418 7.902a3.393 3.393 0 01-2.017.729zm13.365 3.957V7.818c0-.611-.502-1.105-1.121-1.105a1.113 1.113 0 00-1.119 1.105v8.841c0 .61-.502 1.105-1.121 1.105H8.466c-.619 0-1.12.494-1.12 1.105 0 .61.501 1.105 1.12 1.105H24.15c1.857 0 3.361-1.484 3.361-3.315zM9.586 5.608c0-.611-.501-1.106-1.12-1.106H1.744a1.114 1.114 0 00-1.12 1.106c.001.61.502 1.104 1.12 1.105h6.722c.619 0 1.12-.495 1.12-1.105zm0 8.84c0-.61-.501-1.105-1.12-1.105H5.105a1.112 1.112 0 00-1.119 1.106c0 .61.501 1.104 1.119 1.105h3.361c.619 0 1.12-.495 1.12-1.106z'
    />
  </svg>
)

export default SvgEmail1
