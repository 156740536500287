import styled, { css } from 'styled-components'
import { H2, Anchor, LargeWrapper, Button } from '@raysync/common/components'
import { Media } from '@raysync/common/theme'
export const PricingTitle = styled(H2)`
  font-size: 36px;
  font-weight: 700;
  padding: 100px 0 120px 0;
  text-align: center;
  ${Media.phone`
    font-size: .4rem;
    line-height: .52rem;
    padding: .68rem 0 .5rem 0;
  `}
`
export const TabContainer = styled.div`
  width: 500px;
  height: 60px;
  border-radius: 30px;
  background: #ffffff;
  padding: 5px;
  display: flex;
  margin: 0 auto 40px;
  ${Media.phone`
    width: 100%;
    height: .96rem;
    border-radius: .6rem;
    padding: .1rem;
    margin-bottom: .6rem;
  `}
`
export const TabItem = styled.div`
  width: 246px;
  height: 50px;
  border-radius: 28px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  font-size: 18px;
  color: ${p => (p.actived ? '#fff' : '#636772')};
  background: ${p => (p.actived ? p.theme.color.secondary : '#fff')};
  ${Media.phone`
    width: 3.34rem;
    height: .76rem;
    line-height: .76rem;
    border-radius: .42rem;
    font-size: .28rem;
  `}
`
export const PricingTitleContainer = styled.div`
  ${({ theme: { color, typography } }) => css`
    display: flex;
    position: relative;
    z-index: 2;
  `}
`
export const FeatureContainer = styled.div`
  z-index: 2;
`
export const BackgroundWrap = styled.div`
  display: flex;
  position: absolute;
  height: 100%;
`
export const BackgroundBox = styled.div`
  width: 350px;
  background: white;
  z-index: -1;
  border-radius: 10px;
  ${p =>
    p.isEnterprise &&
    css`
      box-shadow: 0px 10px 18px 2px rgba(204, 204, 204, 0.5);
      height: calc(100% + 40px);
      transform: translateY(-10px);
      &::after {
        content: '';
        display: block;
        height: 120px;
        transform: translateY(-15px);
        border-radius: 10px 10px 0px 0px;
        background: ${p => p.theme.color.secondary};
      }
    `}
`
export const FeatureItemContainer = styled.div`
  ${({ theme: { color, typography } }) => css`
    display: flex;
    text-align: center;
    color: ${color.primary};
    font-size: ${typography.textSmall};
    position: relative;
    :hover {
      background: #fff;
      box-shadow: 0px 4px 19px 1px rgba(204, 204, 204, 0.35);
    }
    > div {
      width: 280px;
      :not(:last-child) {
        border-right: 1px solid ${color.panel};
      }
    }
    :not(:last-child) {
      div {
        border-bottom: 1px solid ${color.panel};
      }
    }
  `}
`

export const FeatureTitle = styled.h3`
  ${({ theme: { color, typography } }) => css`
    line-height: 50px;
    text-align: center;
    color: ${color.primary};
    font-size: 20px;
    font-weight: 700;
    background-color: ${color.panel};
  `}
`

export const FeatureItemTitle = styled.div`
  display: flex;
  align-items: center;
  line-height: 18px;
  min-height: 50px;
  text-align: left;
  padding-left: 20px;
  font-weight: 700;
  width: 372px !important;
`
// export const FeatureItem = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 10px 0;
//   width: 372px !important;
//   p {
//     color: #666;
//     text-align: left;
//     b {
//       width: 46px;
//       white-space: nowrap;
//       font-weight: 400;
//       display: inline-block;
//     }
//   }
//   svg {
//     width: 24px;
//     height: 18px;
//   }
// `
export const TitleContent = styled.div`
  text-align: center;
  width: 350px;
  :not(:last-child) {
    border-right: 1px solid ${p => p.theme.color.panel};
  }
  padding: 32px 0;
  > h3 {
    white-space: nowrap;
    color: #0b1a24;
    font-size: 24px;
    font-weight: 600;
  }
  > h4 {
    color: #666;
    font-size: 14px;
    min-height: 76px;
    padding: 0 10px;
    margin: 6px 0 0px;
  }
  > p {
    padding: 0 20px;
    b {
      font-weight: 700;
      font-size: 36px;
      line-height: 1;
      color: ${p => p.theme.color.secondary};
    }
    > span {
      display: block;
      height: 76px;
      color: #0b1a24;
      font-size: 18px;
      font-weight: 700;
      line-height: 1;
      > span {
        font-size: 14px;
        color: #636772;
        display: block;
      }
    }
  }
  a {
    border-radius: 32px;
  }
  ${p =>
    p.isEnterprise &&
    css`
      h3,
      h4 {
        position: relative;
        color: #fff;
      }
    `}
`
export const ConcactSection = styled.div`
  padding: 0px 0 60px 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #636772;
  a {
    padding: 0 10px !important;
    min-width: 260px !important;
    border-radius: 32px;
    margin-left: 20px;
    font-size: 16px !important;
    svg {
      margin-right: 10px;
      path {
        fill: currentColor;
      }
    }
    &:last-child {
      box-shadow: 0px 10px 18px 2px rgba(253, 92, 31, 0.3);
    }
  }
  ${Media.phone`
    padding: 0rem 0rem 1rem;
    a {
      width: 5rem !important;
      margin-top: .2rem;
      margin-left: 0;
      height: .8rem  !important;
      line-height: .8rem  !important;
      border-radius: .4rem;
      svg {
        margin-right: 0.2rem;
      }
    }
    h2 {
      font-size: .32rem;
      line-height: .42rem;
    }
  `};
`

export const NavAnchor = styled(Anchor)`
  ${({ theme: { color, typography } }) => css`
    display: block;
    color: ${color.secondary};
    font-size: ${typography.h4};
    margin-top: 25px;
    :last-child {
      margin-top: 17px;
    }
    ${Media.phone`
      font-size: .3rem;
    `}
  `}
`

export const MobileBox = styled.div`
  ${({ theme: { color } }) => css`
    background: white;
    margin-bottom: .5rem;
    /* border: 1px solid ${color.primary}; */
  `}
`
export const MobileBoxHeader = styled.div`
  height: 2.6rem;
  padding: 0.38rem 0.4rem;
  background: linear-gradient(90deg, rgba(253, 92, 31, 1) 0%, rgba(244, 171, 68, 1) 100%);
  color: white;

  h2 {
    font-size: 0.4rem;
    line-height: 1;
    span {
      b {
        font-weight: normal;
        font-size: 0.32rem;
        margin-right: 0.1rem;
      }
    }
  }
  p {
    font-size: 0.3rem;
    margin-top: 0.24rem;
    line-height: 1.4;
    min-height: 0.84rem;
  }
`
export const MobileFeatureBox = styled.div`
  ${({ theme: { color, Hack } }) => css`
    font-size: 0.24rem;
    box-shadow: 0px 2px 6px 0px rgba(37, 42, 58, 0.1);
    padding: 0.4rem 0 0.7rem 0.42rem;
    > div {
      padding: 0 0 0.42rem 0;
      border-bottom: 1px solid #b4bdc2;
      margin-right: 0.42rem;
      p {
        display: flex;
        justify-content: space-between;
        font-size: 0.3rem;
        color: ${color.primary};
        line-height: 1;
        &:not(:last-child) {
          margin-bottom: 0.22rem;
        }
        ${Hack.mobileForeign`
          font-size: 0.26rem;

          span:last-child {
            text-align: right;
            min-width: 1.15rem;
          }
        `}
      }
    }
    > h3 {
      margin: 0.35rem 0;
      color: #000;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        display: inline-block;
        /* float: left; */
        width: 50%;
        color: ${color.text};
        ${Hack.mobileForeign`
          width: 100%;
        `}
      }
    }
    > a {
      padding-top: 0.5rem;
      padding-right: 0.42rem;
      width: 100%;
    }
  `}
`
export const PricingWrap = styled(LargeWrapper)`
  width: 1400px;
  min-width: 1400px;
`
export const GetSection = styled.div`
  background: #fff;
  padding: 80px 0 100px 0;
  h2 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 70px;
    text-align: center;
  }
  ${Media.phone`
   padding: .8rem 0 1rem 0;
    h2 {
      font-size: .48rem;
      margin-bottom: .7rem;
    }
  `}
`
export const GetWrap = styled.div`
  display: flex;
  justify-content: space-between;
  ${Media.phone`
    flex-wrap: wrap;
  `}
`
export const GetItem = styled.div`
  width: 388px;
  div {
    height: 60px;
    svg {
      height: 60px;
    }
  }
  h4 {
    font-weight: bold;
    font-size: 24px;
    color: #3e4149;
    line-height: 18px;
    margin: 30px 0 26px;
  }
  p {
    font-size: 16px;
    color: #636772;
    line-height: 1.5;
  }
  ${Media.phone`
    width: 100%;
    margin-bottom: .6rem;
    div {
     height: .6rem;
      svg {
        height: .6rem;
      }
    }
    h4 {
      font-size: .3rem;
      margin: .2rem 0 .12rem;
    }
    p {
      font-size: .2rem;
    }
  `}
`

export const PricingWrapper = styled.div`
  background: #f4f4f4;
  > h2 {
    padding: 80px 0 60px;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
  }
  h3 {
    margin: 78px auto 34px;
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    color: #252a3a;
  }
  ${Media.phone`
    padding: 0 .3rem;
     h2 {
       padding: .8rem 0 .6rem;
       font-size: .48rem;
       line-height: 1.2;
     }
     h3 {
       max-width: 4.5rem;
       margin: .18rem auto .34rem;
       font-size: .4rem;
       line-height: 1.2;
     }
   `}
`
export const PricingContainer = styled.div`
  ${({ theme: { Hack } }) => css`
    width: 1220px;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    ${Hack.foreign`
      width: 1400px;
    `}
    ${Media.phone`
      flex-wrap: wrap;
      width: 100%;
   `}
  `}
`

export const PricingBox = styled.div`
  ${({ theme: { Hack } }) => css`
    width: 380px;
    border-radius: 10px;
    background: #fff;
    &:hover {
      box-shadow: 0px 10px 24px 6px rgba(204, 204, 204, 0.35);
    }
    & + & {
      margin-left: 50px;
    }
    ${Hack.foreign`
      width: 440px;
    `}
    ${Media.phone`
      width: 100%;
      margin-left: unset !important;
      margin-bottom: .6rem;
   `}
  `}
`
export const TitleBox = styled.div`
  height: 140px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  background-size: cover !important;
  background-repeat: no-repeat;
  img {
    display: block;
    margin: 0 auto 10px;
  }
  h5 {
    width: 100%;
    line-height: 1;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: #fff;
  }
  ${Media.phone`
      height: 2.25rem;
      font-size: .14rem;
      img {
        margin-bottom: 0.16rem;
      }
      h5 {
        font-weight: .2rem;
      }
   `}
`
export const EnTitleBox = styled(TitleBox)`
  justify-content: space-between;
  padding: 0 30px;
  p {
    display: flex;
    align-items: center;
    color: #fff;
    b {
      font-weight: bold;
      font-size: 36px;
    }
    a {
      display: inline-block;
      text-align: center;
      width: 150px;
      height: 26px;
      line-height: 26px;
      color: ${p => p.theme.color.secondary};
      background: #ffffff;
      border-radius: 4px;
      padding: 0;
      font-size: 14px;
      cursor: pointer;
    }
  }
  ${Media.phone`
      padding: 0 .3rem;
      b {
        font-size: .36rem;
      }
      a {
        width: 1.5rem;
        height: .26rem;
        line-height: .26rem;
        font-size: .2rem;
      }
   `}
`
export const ContentBox = styled.div`
  padding: 40px 30px;
  ${Media.phone`
   padding: .7rem .4rem; 
  `}
`

export const ConfigBox = styled.ul`
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 0px;
    &::before {
      display: none;
    }
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    p {
      font-size: 14px;
      color: #999999;
    }
    b {
    }
  }
  ${Media.phone`
    li {
        p , b {
        font-size: .24rem;
      }
    }
  `}
`
export const FeatureItem = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
  }
  span {
    font-size: 16px;
    font-weight: ${p => (p.enabled ? 'bold' : '400')};
    color: ${p => (p.enabled ? '#252A3A' : '#999999')};
  }
  ${Media.phone`
    margin-top: .3rem;
    span {
      font-size: .24rem;
    }
  `}
`
export const ButtonConainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`

export const PricingButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 40px;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  border-radius: 18px;
  transition: all 0.3s linear;
  ${p =>
    p.color1 &&
    css`
      color: ${p => p.color1} !important;
    `}
  ${p =>
    p.bg &&
    css`
      background-color: ${p => p.bg} !important;
    `}
  :hover {
    box-shadow: 0px 3.53px 17.63px 0px ${p => p.hoverColor};
  }
  ${Media.phone`
    width: 5rem;
    padding: 0;
    height: .8rem;
    line-height: .8rem;
    border-radius: .4rem;
  `}
`
