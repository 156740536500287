import React from 'react'
import intl from 'react-intl-universal'
import { range } from '@raysync/common/utils'
import { Navigation } from '@raysync/common/services'
import { H3, H2, Button } from '@raysync/common/components'
import {
  PricingContainer,
  FeatureItem,
  ConcactSection,
  PricingWrapper,
  PricingBox,
  TitleBox,
  ContentBox,
  ConfigBox,
  ButtonConainer,
  PricingButton,
  TabContainer,
  TabItem,
} from './atoms'
import EmailIcon from '@raysync/common/components/svg/email1'
import Layout from '../../../components/layout'
import Bg3 from './images/bg3.png'
import Bg4 from './images/bg4.png'
import Bg5 from './images/bg5.png'

import Icon1 from './images/smb.png'
import Icon2 from './images/enterprise.png'
import Icon3 from './images/cloud.png'
import RightIcon from './images/yes.png'
import ErrorIcon from './images/no.png'

const Pricing = props => {
  const [currentTab, setCurrentTab] = React.useState('file-transfer')
  const list = [
    {
      hide: currentTab !== 'file-transfer',
      title: intl.get('pricing.profession.title'),
      enableFeature: [true, true, true, true, true, true, true, false, false, false, false, false, false],
      bg: Bg3,
      icon: Icon1,
      price: intl.getHTML('pricing.feature0.item1.point1'),
      button: {
        text: intl.get('pricing.profession.button'),
        variant: 'contained',
        color: '',
        hoverColor: 'rgb(253, 92, 31,0.3)',
        link: '/apply',
      },
    },
    {
      hide: currentTab !== 'file-transfer',
      title: intl.get('pricing.enterprise.title'),
      enableFeature: [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true],
      bg: Bg4,
      price: intl.getHTML('pricing.feature0.item1.point4'),
      icon: Icon2,
      button: {
        text: intl.get('pricing.profession.button'),
        variant: 'contained',
        color: '#fff',
        bg: '#24242B',
        hoverColor: 'rgba(36,36,43,.3)',
        link: '/apply',
      },
    },
    {
      hide: currentTab !== 'cloud',
      title: '跨网文件交换系统',
      features: [
        '大小文件高速传输',
        '支持主流存储',
        '邀请上传',
        '分享下载',
        '群组文件库',
        '文件杀毒',
        '敏感词检测',
        '跨网文件投递',
        '投递文件审核',
        '企业用户 (SSO) 集成',
        'Web页面自定义',
        '事件处理',
        '在线预览',
      ],
      limit: ['Cloud', '不限制', '不限制', 'License 控制'],
      enableFeature: [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true],
      bg: Bg5,
      price: intl.getHTML('pricing.feature0.item1.point4'),
      icon: Icon3,
      button: {
        text: intl.get('pricing.profession.button'),
        variant: 'contained',
        color: '#fff',
        bg: '#0595FD',
        hoverColor: 'rgba(5, 149, 253, 0.3)',
        link: '/apply',
      },
    },
  ]

  return (
    <Layout pageType='pricing' location={props.location}>
      <PricingWrapper>
        <H2>{intl.get('pricing.title')}</H2>
        <TabContainer>
          <TabItem
            actived={currentTab === 'file-transfer'}
            onMouseEnter={() => {
              setCurrentTab('file-transfer')
            }}
          >
            大文件传输系统
          </TabItem>
          <TabItem
            actived={currentTab === 'cloud'}
            onMouseEnter={() => {
              setCurrentTab('cloud')
            }}
          >
            跨网文件交换系统
          </TabItem>
        </TabContainer>
        <PricingContainer>
          {list.map((item, i) => {
            return item.hide ? (
              <></>
            ) : (
              <PricingBox key={item.title}>
                <TitleBox style={{ background: `url(${item.bg})  100% 100%` }}>
                  <img src={item.icon} alt='' />
                  <h5>{item.title}</h5>
                </TitleBox>

                <ContentBox>
                  <ConfigBox>
                    <li>
                      <p>{intl.get('pricing.transmission')}</p>
                      <b>{intl.get(`pricing.transmission.item${i + 1}`)}</b>
                    </li>
                    <li>
                      <p>{intl.get('pricing.volume')}</p>
                      <b>{intl.get(`pricing.volume.item${i + 1}`)}</b>
                    </li>
                    <li>
                      <p>{intl.get('pricing.users')}</p>
                      <b>{intl.get(`pricing.users.item${i + 1}`)}</b>
                    </li>
                    <li>
                      <p>{intl.get('pricing.maximum-user-number')}</p>
                      <b>{intl.get(`pricing.maximum-user-number.item${i + 1}`)}</b>
                    </li>
                  </ConfigBox>
                  <ButtonConainer>
                    <PricingButton
                      color1={item.button.color}
                      bg={item.button.bg}
                      variant={item.button.variant}
                      hoverColor={item.button.hoverColor}
                      fullWidth
                      onClick={() => {
                        Navigation.to({ link: item.button.link })
                      }}
                    >
                      {item.button.text}
                    </PricingButton>
                  </ButtonConainer>
                  {range(13).map(i => {
                    return (
                      <FeatureItem key={i} enabled={item.enableFeature[i]}>
                        <img src={item.enableFeature[i] ? RightIcon : ErrorIcon} alt='document card' />
                        <span>{item.features ? item.features[i] : intl.get(`pricing.feature${i + 1}`)}</span>
                      </FeatureItem>
                    )
                  })}
                </ContentBox>
              </PricingBox>
            )
          })}
        </PricingContainer>
        <H3>{intl.get('pricing.more')}</H3>
        <ConcactSection>
          <Button as='a' rel='noopener noreferrer' href={`mailto:${intl.get('app.contact.email.value')}`}>
            <EmailIcon />
            {`${intl.get('app.contact.email.value')}`}
          </Button>
        </ConcactSection>
      </PricingWrapper>
    </Layout>
  )
}

export default Pricing
